This privacy policy informs you about how deltaDAO AG (in the following deltaDAO, we, us, our) processes your personal data when you visit our Pontus-X Blockchain Explorer (in the following explorer) and when you contact us. Moreover, this privacy policy informs you about your rights.

_Last updated on February 23, 2024._

### 1. Contact details of the controller and Data Protection Officer

The controller pursuant to the EU General Data Protection Regulation ("GDPR") for the processing of your personal data is:

deltaDAO AG
Katharinenstraße 30a
20457 Hamburg
Germany
E-mail: contact@delta-dao.com

If you have any questions about the protection of your personal data at deltaDAO, please contact our Data Protection Officer:

Data Protection Officer
deltaDAO AG
Katharinenstraße 30a
20457 Hamburg
Germany
E-mail: privacy@delta-dao.com

### 2. What's personal data?

Personal data is any information that can be (directly or indirectly) related to you. deltaDAO processes a minimal amount of personal data, as we believe your personal data belongs to you. We process the following personal data.

- **Logfile data and IP addresses:** Logfile data including IP addresses are processed when visiting our explorer.
- **E-mail:** If you contact deltaDAO via e-mail, we process your e-mail address and any personal data you decide to provide in your message (such as your name).

For detailed information about the processing operations, lawfulness, purposes, and how your personal data serves to reach these purposes, please take a look at the chapter "Processing operations according to Article 13 GDPR".

### 3. Recipients and cross-border data transfer

**Visiting our explorer**
When visiting our explorer, your IP address and logfile data are processed by Vercel Inc., 340 S Lemon Ave #4133, Walnut, CA 91789, USA. Vercel is a service provider that hosts our explorer. Our explorer is served by Vercel using a Content Delivery Network, a geographically distributed network, with servers within and outside of the European Economic Area (EEA). This means, if you are located within the EEA, your personal data will most likely (but not guaranteed) be processed on a Vercel server within the EEA.

There is no adequacy decision for the USA from the European Commission. Our cooperation with Vercel is based on a Data Processing Agreement (DPA) including Standard Contractual Clauses (SCC). You have the right to receive a copy of these SCC. To exercise your right, please contact us at <privacy@delta-dao.com>.

- Here you can find Vercel&#39;s current [**DPA including SCC** &#8599;](https://vercel.com/legal/dpa).
- Here you can find Vercel&#39;s [**privacy policy** &#8599;](https://vercel.com/legal/privacy-policy).

**Contact via e-mail**
When you contact us via e-mail, our (mail) service provider Microsoft Corporation (located at 1 Microsoft Way, Redmond, Washington 98052-8300, USA) supports us in processing your personal data so we can communicate with you.

There is no adequacy decision for the USA from the European Commission. However, Microsoft is EU-US DPF (EU‐US Data Privacy Framework) certified. The EU‐US DPF is an adequacy decision of the European Commission, limited to certified entities. Moreover, we have restricted storage on the EEA and signed SCC with our provider. You have the right to receive a copy of these SCC. To exercise your right, please contact us at <privacy@delta-dao.com>.

- Here you can find Microsoft&#39;s current [**DPA including SCC** &#8599;](https://www.microsoft.com/licensing/docs/view/Microsoft-Products-and-Services-Data-Protection-Addendum-DPA).
- Here you can find Microsoft&#39;s [**privacy policy** &#8599;](https://privacy.microsoft.com/de-de/privacystatement).
- Here you can find Microsoft&#39;s [**Data Privacy Framework List entry** &#8599;](https://www.dataprivacyframework.gov/list).

### 4. Processing operations according to Article 13 GDPR

#### **4.1 Providing the Pontus-X Blockchain Explorer**

The Pontus-X Blockchain Explorer for the Pontus-X ParaTime enables users to inspect and analyze the transactions made on the Pontus-X ParaTime, including the tracking of transaction histories, wallet interactions, and smart contract operations. Logfile data and IP addresses are collected, used, and stored for providing our explorer and its functionalities and to maintain its security. Our explorer is hosted externally by our service provider Vercel (see also chapter 3).

**Purposes:** Collecting and using your IP address is necessary for providing our explorer because it is a technical requirement for ensuring communication between your device and our explorer. Logfiles including your IP address are created for security, fraud-prevention, abuse-prevention, and troubleshooting purposes.

**Legal basis:** The legal basis for this processing is our legitimate interest, pursuant to Art. 6(1)(f) GDPR.

**Legitimate interests:** Our legitimate interest is to provide our explorer and its functionalities to you and to enable security, a technically error-free presentation, and the optimization of the explorer.

**Retention period:** Vercel retains your personal data for as long as needed to provide their services. Additionally, Vercel does not delete the data when it is needed for the establishment, exercise, or defense of legal claims. In this case, the information is retained as long as needed for exercising respective potential legal claims.

#### **4.2 Contact via e-mail**

If you contact us via e-mail, deltaDAO collects, uses and stores your e-mail address, and any other information you provide us in your message, such as your name.

**Purposes:**
We collect, use and store this personal data to respond to your inquiries.

**Legal basis:**
The legal basis for this processing is our legitimate interest, according to Art. 6(1)(f) GDPR.

**Legitimate interests:**
Our legitimate interest is to answer your inquiries.

**Retention period:**
deltaDAO deletes your personal data as soon as we no longer require them for processing your inquiry, except deltaDAO is obliged to comply with legal retention periods or in case of legal disputes.

### 5. Automated decision making including profiling according to Article 13(2)(f) GDPR

Automated decision making including profiling does not take place.

### 6. Cookies

A cookie is a small file that stores Internet settings. Your web browser downloads it on the first visit to a website. The next time you open this website with the same device, the cookie and the information stored in it are either sent back to the website that created it (first-party cookie) or sent to another website it belongs to (third-party cookie). This enables the website to detect that you have opened it previously with this browser and, in some cases, to vary the displayed content.

Our explorer does not use cookies.

### 7. External links

Our explorer contains links to external websites that are beyond the control and responsibility of deltaDAO.

### 8. Your rights

If you want to make use of your rights described below, do not hesitate to contact us.

**8.1 Right of access (Art. 15 GDPR)**

You have the right to obtain confirmation as to whether deltaDAO processes personal data about you. If we are processing personal data about you, you have the right to access these personal data and to gain the information defined in Art. 15 GDPR.

**8.2 Right to rectification (Art. 16 GDPR)**

You have the right to obtain without undue delay the rectification of inaccurate personal data about you. Additionally, you have the right that incomplete personal data about you are completed.

**8.3 Right to erasure (Art. 17 GDPR)**

You have the right to obtain without undue delay the rectification of inaccurate personal data about you. Additionally, you have the right that incomplete personal data about you are completed.

**8.4 Right to restriction (Art. 18 GDPR)**

Moreover, you have the right to obtain the restriction of processing your personal data where the defined legal grounds in Art. 18 GDPR apply.

**8.5 Right to data portability (Art. 20 GDPR)**

You have the right to receive your personal data in a structured, commonly used, and machine-readable format. Additionally, you have the right to transmit those data to another controller without hindrance, where the defined legal grounds in Art. 20 GDPR apply. You can make use of your right to data portability by contacting us.

**8.6 Right to object (Art. 21 GDPR)**

On grounds relating to your particular situation, you have the right to object to the processing of your personal data where we based the processing on legitimate interests (Art. 6(1)(f) GDPR). If you object, deltaDAO will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing, overriding your rights, freedoms, and interests, or if the processing is required to establish, exercise, or defend legal claims.

**8.7 Right to lodge a complaint (Art. 77 GDPR)**

You have the right to lodge a complaint with a supervisory authority if you consider the processing of your personal data by deltaDAO to infringe the GDPR. You can lodge a complaint in particular

- in the Member State of your habitual residence,
- in the Member State of your place of work, and
- in the place of the alleged infringement.

### 9. Questions

For any requests regarding our privacy policy, please send us an e-mail to [privacy@delta-dao.com](mailto:privacy@delta-dao.com).

### 10. Changes to the Privacy Policy

We adjust this policy from time to time, by publishing a new version on our explorer. You can find the date of the current version at the beginning of this policy. The latest version of this policy applies to the processing of your personal data.
